<template>
  <div style="height: inherit">
    <b-overlay :show="!ready || !currencyReady || !cartReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <!-- ECommerce Header -->
      <section id="ecommerce-header" v-if="currencyReady && cartReady">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <!-- <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" /> -->
                <div class="search-results" v-if="filteredProductsCount > 0">{{ filteredProductsCount }} {{ $t('shop.result_found') }}</div>
              </div>
              <div class="view-options d-flex">
                <!-- Sort Button -->
                <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="$t(selectedSortType.value)" right variant="primary">
                  <b-dropdown-item v-for="sortOption in shopSortTypes" :key="`sort_option_${sortOption.value}`" @click="changeSortType(sortOption)">
                    {{ $t(sortOption.value) }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="productView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="primary">
                  <b-form-radio v-for="option in productViewOptions" :key="`view_option_${option.value}`" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="search" :placeholder="$t('shop.search_placeholder')" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <template v-if="filteredProducts.length > 0">
        <section :class="productView" v-if="productView === 'grid-view'">
          <b-card v-for="(product, index) in filteredProducts" :key="`product_grid_${index}_${product.product_id}`" class="ecommerce-card" no-body>
            <div class="product-group-name">
              {{ product.product_group_name }}
            </div>

            <div class="product-image-div d-flex justify-content-center align-items-center">
              <div class="product-is-new bg-light-success rounded" v-if="product.is_new_product === 1" v-html="$t('shop.is_new')" />
              <div class="product-is-discount bg-light-success rounded" v-if="product.is_discount === 1" v-html="$t('shop.discounted_product')" />
              <b-img class="cursor-pointer product-image" :src="getProductImage(product)" @click="openProduct(product.product_id)" />
            </div>
            <!-- Product Details -->
            <b-card-body class="product-detail">
              <hr />

              <p class="barcode-text">{{ `${$t('shop.barcode_tooltip')}: ${product.barcode}` }}</p>
              <p class="ellipsis-text">{{ `${$t('shop.product_code_tooltip')}: ${product.product_code}` }}</p>
              <p v-if="product.orginal_code" class="ellipsis-text">
                {{ `${$t('shop.orginal_code_tooltip')}: ${product.orginal_code}` }}
              </p>

              <p class="product-name my-1" @click="openProduct(product.product_id)">
                {{ product.product_name }}
                <template v-if="product.attribute_name">
                  <br />
                  <span>{{ product.attribute_name }}</span>
                </template>
              </p>

              <table class="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th class="w-100"></th>
                    <th class="text-center">USD</th>
                    <th class="text-center">TRY</th>
                  </tr>
                </thead>
                <tbody :set="(priceDetail = priceTooltip(product))">
                  <tr>
                    <th class="pb-1">{{ $t('shop.unit_price') }}</th>
                    <td class="px-2 text-right text-nowrap font-weight-bolder">{{ priceDetail.LIST_SHOW }}</td>
                    <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.TRY_SHOW }}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="pb-1">{{ `${$t('shop.vat')}(%${product.vat_rate})` }}</th>
                    <td class="px-2 text-right text-nowrap font-weight-bolder">{{ priceDetail.LIST_VAT_SHOW }}</td>
                    <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ priceDetail.TRY_VAT_SHOW }}</td>
                  </tr>
                  <tr>
                    <th class=" pb-1">{{ $t('shop.unit_price_with_vat') }}</th>
                    <td class=" px-2 text-right text-nowrap font-weight-bolder">
                      <h4 class="font-weight-bolder text-nowrap text-uppercase m-0">
                        {{ priceDetail.USD_PWV_SHOW }}
                      </h4>
                    </td>
                    <td class=" px-2 text-right text-nowrap font-weight-bolder ">
                      <h4 class="font-weight-bolder text-nowrap text-uppercase m-0 text-warning">{{ priceDetail.TRY_PWV_SHOW }}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <div class="item-actions-div">
                <div class="action-wishlist">
                  <b-button variant="light" tag="a" class="btn-wishlist" block @click="toggleProductInWishlist(product)">
                    <feather-icon icon="BookmarkIcon" size="20" class="mr-50" />
                    <span class="wish-list-text">{{ $t('shop.wish_list') }} </span>
                  </b-button>
                </div>
                <div class="action-cart" v-if="product.qty_avaible > 0 && product.unit_price_tra >= 0">
                  <change-quantity v-if="checkInCart(product.product_id)" idPrefix="grid_shop_item" :productId="product.product_id" :maxAmount="product.qty_avaible < product.qty_order_max ? product.qty_avaible : product.qty_order_max" />
                  <b-button v-else variant="primary" tag="a" class="btn-cart" block @click="handleCartActionClick(product)" :disabled="product.cartButtonState">
                    <feather-icon icon="ShoppingCartIcon" size="20" class="mr-50" />
                    <span>{{ $t('shop.add_to_cart') }}</span>
                  </b-button>
                </div>
                <div class="action-cart" v-else>
                  <b-button tag="a" variant="outline-success" class="btn-cart" block @click="notAvaible()">
                    <feather-icon icon="PhoneIcon" size="20" class="mr-50" />
                    <span v-html="$t('shop.not_avaible')" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-card>
        </section>
        <b-table v-else striped responsive :items="filteredProducts" :fields="fields" class="list-view-table rounded">
          <template #cell(product_photo)="data">
            <product-tooltip :id="'product-photo-' + data.item.product_id" :product="data.item" class="product_table_image" />
            <b-popover :target="'product-photo-' + data.item.product_id" placement="right" variant="primary" triggers="hover">
              <product-tooltip :product="data.item" />
            </b-popover>
          </template>
          <template #cell(product)="data">
            <div :id="'product-' + data.item.product_id" tabindex="-1" class="product-list-detail" @click="goToProductPage(data.item.product_id)">
              <p class="barcode-text">{{ `${$t('shop.barcode_tooltip')}: ${data.item.barcode}` }}</p>
              <p class="ellipsis-text">{{ `${$t('shop.product_code_tooltip')}: ${data.item.product_code}` }}</p>
              <p v-if="data.item.orginal_code" class="ellipsis-text">
                {{ `${$t('shop.orginal_code_tooltip')}: ${data.item.orginal_code}` }}
              </p>

              <p class="product-name mt-1">
                {{ data.item.product_name }}
                <template v-if="data.item.attribute_name">
                  <br />
                  <span>{{ data.item.attribute_name }}</span>
                </template>
              </p>
            </div>
          </template>
          <template #cell(list_price)="data">
            <div v-if="data.item.currency === 'USD'" class="d-flex justify-content-end align-items-center">
              <h5 class="font-weight-bolder text-nowrap text-uppercase m-0 mr-1">{{ priceTooltip(data.item).LIST_SHOW }}</h5>
              <span class="text-muted text-nowrap text-uppercase">{{ `+ %${data.item.vat_rate} ${$t('shop.vat')}` }}</span>
            </div>
          </template>
          <template #cell(try_price)="data">
            <div class="d-flex justify-content-end align-items-center">
              <h5 class="font-weight-bolder text-nowrap text-uppercase m-0 mr-1">{{ priceTooltip(data.item).TRY_SHOW }}</h5>
              <span class="text-muted text-nowrap text-uppercase">{{ `+ %${data.item.vat_rate} ${$t('shop.vat')}` }}</span>
            </div>
          </template>
          <template #cell(unit)="data">
            <span class="font-weight-bold">{{ data.item.sales_unit }}</span>
          </template>
          <template #cell(process)="data">
            <div class="product-actions">
              <div v-if="data.item.qty_avaible > 0 && data.item.unit_price_tra >= 0" class="product-actions-quantity">
                <template v-if="checkInCart(data.item.product_id)">
                  <change-quantity
                    @changeQuantity="nextTab(data.item.product_id)"
                    idPrefix="table_shop_item"
                    :productId="data.item.product_id"
                    :maxAmount="data.item.qty_avaible < data.item.qty_order_max ? data.item.qty_avaible : data.item.qty_order_max"
                    :others="false"
                  />
                  <b-button tag="a" variant="danger" @click="removeProductFromCart(data.item.product_id)" tabindex="-1">
                    <feather-icon icon="TrashIcon" size="20" />
                  </b-button>
                </template>
                <template v-else>
                  <cleave
                    :id="`table_shop_item_${data.item.product_id}`"
                    ref="input"
                    v-model="data.item.quantity"
                    :options="cleaveOptions"
                    class="form-control h-auto border-primary rounded"
                    @keyup.native="quantityInput(data.item)"
                    @keyup.native.enter="addCartInList(data.item, $event)"
                  />
                  <b-button variant="primary" tag="a" @click="handleCartActionClick(data.item, false)" :disabled="data.item.cartButtonState" tabindex="-1">
                    <feather-icon icon="ShoppingCartIcon" size="20" />
                  </b-button>
                </template>
              </div>
              <div v-else class="product-actions-not-avaible">
                <b-button tag="a" variant="outline-success" @click="notAvaible()" tabindex="-1">
                  <feather-icon icon="PhoneIcon" size="20" />
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </template>
      <section v-else>
        <h3 class="mt-2">{{ $t('shop.not_found') }}</h3>
      </section>

      <!-- Pagination -->
      <section v-if="filteredProducts.length > 0">
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="page" :total-rows="filteredProductsCount" :per-page="shopPerPage" first-number align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <!-- <portal to="content-renderer-sidebar-detached-left">
        <product-filters :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
      </portal> -->
    </b-overlay>
  </div>
</template>

<script>
import { BDropdown, BTable, BPopover, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProductFilters from './ProductFilters.vue'
import ChangeQuantity from './ChangeQuantity.vue'
import { convertNumbertoString, convertTL, priceFormat, priceTooltip, priceWithVat } from '@/assets/js/functions/currency-functions'
import { CHANGE_SHOP_PAGE_EVENT, CHANGE_SORT_TYPE, GET_ALL_PRODUCTS, TYPE_SEARCH } from '@/store/services/shop-service'
import { mapGetters } from 'vuex'
import { ADD_CART_EVENT, REMOVE_CART_EVENT } from '@/store/services/checkout-service'
import ProductTooltip from '@/views/Order/Product/Tooltip.vue'
import Cleave from 'vue-cleave-component'
import { SET_NOTIFICATION } from '@/store/services/module-service'

export default {
  name: 'Shop',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BOverlay,
    BTable,
    BPopover,
    VBTooltip,

    // SFC
    ProductFilters,
    ProductTooltip,

    ChangeQuantity,
    Cleave
  },
  data() {
    return {
      productView: 'list-view',
      ready: false,
      productViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' }
      ],
      mqShallShowLeftSidebar: false,
      focusProduct: undefined
    }
  },
  created() {
    this.$store
      .dispatch(GET_ALL_PRODUCTS)
      .then(() => {
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      })
      .then(() => {
        this.ready = true
      })
  },
  computed: {
    ...mapGetters([
      'filteredProducts',
      'filteredProductsCount',
      'shopSortTypes',
      'selectedSortType',
      'shopSearch',
      'shopPage',
      'shopPerPage',
      'cart',
      'cartReady',
      'wishlist',
      'productImages',
      'getLocale',
      'currency',
      'brandLogo',
      'currencyReady',
      'isMasterCompany',
      'systemSettings'
    ]),
    search: {
      get() {
        return this.shopSearch
      },
      set(val) {
        this.$store.commit(TYPE_SEARCH, val)
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      }
    },
    page: {
      get() {
        return this.shopPage
      },
      set(val) {
        const rootEle = document.documentElement
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, val)
      }
    },
    fields() {
      return [
        {
          key: 'product_photo',
          label: this.$t('shop.product_photo')
        },
        {
          key: 'product',
          label: this.$t('product.info')
        },
        {
          key: 'list_price',
          label: this.$t('shop.list_price'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'try_price',
          label: this.$t('shop.try_price'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'unit',
          label: this.$t('shop.unit'),
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          key: 'process',
          label: this.$t('Processes'),
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ]
    },
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    changeSortType(val) {
      this.$store.commit(CHANGE_SORT_TYPE, val)
      this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
    },
    checkInCart(id) {
      return this.cart.findIndex(x => x.product_id === id) !== -1
    },
    productPriceOld(product) {
      let price = product.disc_amt + product.unit_price_tra
      let type = product.currency
      return priceFormat(price, this.getLocale, type)
    },
    productPrice(product) {
      let price = product.unit_price_tra
      let type = product.currency
      return priceFormat(price, this.getLocale, type)
    },
    productPriceTL(product) {
      let price = product.unit_price_tra
      let rate = product.vat_rate
      let type = product.currency
      price = convertTL(price, type, this.currency)
      price = priceWithVat(price, rate)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    priceTooltip(product) {
      let price = product.unit_price_tra
      let rate = product.vat_rate
      let type = product.currency
      let tooltip = priceTooltip(price, rate, type, this.currency, this.getLocale)
      return tooltip
    },
    compatibleBrandsTooltip(brands) {
      let html = `<table>
        <tr>
          <td>
            ${this.$t('shop.compatible_brands_tooltip')}
          </td>
        </tr>`
      brands.forEach(item => {
        html += `
        <tr>
          <td>${item}</td>
        </tr>
        `
      })
      html += `</table>`
      return html
    },
    openProduct(id) {
      let routeData = this.$router.resolve({
        name: '/Shop/Product',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    handleCartActionClick(product, is_new = true) {
      this.$store.dispatch(ADD_CART_EVENT, {
        product_id: product.product_id,
        price_list_detail_id: product.price_list_d_id,
        quantity: is_new ? 1 : +product.quantity
      })
    },
    removeProductFromCart(product_id){
      let product = this.cart.find(x => x.product_id === product_id)
      this.$store.dispatch(REMOVE_CART_EVENT, {
        product_id: product.product_id,
        price_list_detail_id: product.price_list_d_id,
        quantity: product.quantity * 1,
        cart_id: product.cart_id
      })
    },
    toggleProductInWishlist(product) {
      //this.$store.commit(TOGGLE_WISHLIST, product);
    },
    getProductImage(product) {
      let tempImages = JSON.parse(product.product_images)
      if (tempImages?.length) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${tempImages[0].image_id}`
      }
      return this.brandLogo
    },
    cartItem(id) {
      return this.cart.find(x => x.product_id === id)
    },
    notAvaible() {
      this.$swal({
        text: this.$t('shop.not_avaible'),
        html: this.$t('shop.not_avaible_html'),
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-info'
        },
        confirmButtonText: this.$t('payment.error_button_text'),
        buttonsStyling: false
      })
    },
    stockInfo(product) {
      return {
        qty_avaible: product.qty_avaible,
        qty_order_max: product.qty_order_max
      }
    },
    stockInfoTooltip(product) {
      let stock = convertNumbertoString(product.qty_stock, 0, 0)
      let reserved = convertNumbertoString(product.qty_reserve, 0, 0)
      let remain = convertNumbertoString(product.qty_avaible, 0, 0)

      return `<h4 class="text-white">${this.$t('shop.stock_info')}</h4>
        <table class="price-tooltip-table">
          <tr>
            <td class="text-left">
              <h4 class="text-white">${this.$t('shop.current_stock')}</h4
            </td>
            <td>
              <h4 class="text-white">:</h4>
            </td>
            <td class="text-right">
              <h4 class="text-white">${stock}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <h4 class="text-white">${this.$t('shop.reserved_stock')}</h4>
            </td>
            <td>
              <h4 class="text-white">:</h4>
            </td>
            <td class="text-right">
              <h4 class="text-white">${reserved}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <h4 class="text-white">${this.$t('shop.remain_stock')}</h4>
            </td>
            <td>
              <h4 class="text-white">:</h4>
            </td>
            <td class="text-right">
              <h4 class="text-white">${remain}</h4>
            </td>
          </tr>
        </table>
      `
    },
    whatsappHref(product) {
      let url = `https://wa.me/90${this.systemSettings?.whatsapp_phone ?? ''}?text=Merhaba, '${product.brand_name} ${product.product_name}' ürünü hakkında bilgi almak istiyorum`
      window.open(url, '_blank')
    },
    quantityInput(product) {
      let q = product.quantity * 1
      let max = product.qty_avaible < product.qty_order_max ? product.qty_avaible : product.qty_order_max
      if (q > max) {
        let temp = {
          show: true,
          type: 1,
          title: this.$t('notifications.quantity_warning_title'),
          message: this.$t('notifications.quantity_warning_max_message', { quantity: max }),
          variant: 'warning'
        }
        this.$store.commit(SET_NOTIFICATION, temp)
        this.product.quantity = max
        return false
      }
    },
    addCartInList(product) {
      if (+product.quantity > 0) {
        this.$store.dispatch(ADD_CART_EVENT, {
          product_id: product.product_id,
          price_list_detail_id: product.price_list_d_id,
          quantity: product.quantity
        })
        product.quantity = '1'
        this.nextTab(product.product_id)
      }
    },
    nextTab(product_id) {
      let check = undefined
      this.filteredProducts.forEach(item => {
        if (product_id === check) {
          let temp = document.getElementById(`shop_item_${item.product_id}`)
          if (temp) {
            temp.focus()
            temp.select()
          }
        }
        check = item.product_id
      })
    },
    goToProductPage(product_id) {
      let temp = this.$router.resolve({
        name: '/Shop/Product',
        params: { id: product_id }
      })
      window.open(temp.href, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';

.sidebar-shop {
  z-index: 1000 !important;
}

.item-name {
  padding-right: 5px;
}
</style>

<style lang="scss" scoped>
.swiper-navigations {
  width: 100%;
}

.product-image-div {
  min-height: 300px;
}

.product-image {
  width: 300px !important;
  max-width: 300px !important;
  object-fit: cover;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.list-view {
  .product-detail {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
}

.item-actions-div {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  min-height: 50px;

  .action-wishlist {
    .wish-list-text {
      display: none;
    }

    height: 100%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .action-cart {
    white-space: nowrap;
    height: 100%;

    .btn-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom-right-radius: 0.428rem;
    }
  }
}

.product-group-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 5px 0 5px;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 12px;
  letter-spacing: 5px;
}

.product-is-new {
  display: flex;
  position: absolute;
  top: 0.8rem;
  left: -5.2rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.product-is-discount {
  display: flex;
  position: absolute;
  top: 2.5rem;
  left: -3.8rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.product-detail {
  $font-size: 1rem;

  .product-states {
    display: flex;
    gap: 8px;

    .state {
      width: 33px;
      height: 33px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .product-name {
    display: -webkit-box;
    font-size: $font-size;
    line-height: $font-size;
    width: 100%;
    height: $font-size * 2;
    margin: 0;
    padding: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;

    @media screen and (min-width: 1280px) {
      font-size: $font-size + 0.1rem;
      line-height: $font-size + 0.2rem;
      height: ($font-size + 0.2rem) * 2;
    }

    @media screen and (min-width: 1440px) {
      font-size: $font-size + 0.25rem;
      line-height: $font-size + 0.35rem;
      height: ($font-size + 0.35rem) * 2;
    }
  }
}

.product-list-detail {
  $font-size: 1rem;
  cursor: pointer;

  .product-name {
    display: -webkit-box;
    font-size: $font-size + 0.1rem;
    line-height: $font-size + 0.3rem;
    max-width: 30rem;
    max-height: ($font-size + 0.3rem) * 2;
    margin: 0;
    padding: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
  }

  .ellipsis-text {
    font-size: $font-size - 0.1rem;
  }
}

.ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.fa-whatsapp {
  width: 30px;
  height: 30px;
}

.list-view-table {
  margin-top: 2rem;
}

.product-actions {
  height: 3rem;
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
  .product-actions-quantity {
    height: 100%;
    display: flex;
    gap: 0.25rem;
    .form-control {
      text-align: center;
    }
  }

  .product-actions-not-avaible {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product_table_image {
  width: 9rem !important;
}

.barcode-text {
  font-size: 1.1rem;
  line-height: 1.3rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 800;
}
</style>
