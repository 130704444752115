import { render, staticRenderFns } from "./ProductFilters.vue?vue&type=template&id=ec2ea2e6&scoped=true&"
import script from "./ProductFilters.vue?vue&type=script&lang=js&"
export * from "./ProductFilters.vue?vue&type=script&lang=js&"
import style0 from "./ProductFilters.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ProductFilters.vue?vue&type=style&index=1&id=ec2ea2e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec2ea2e6",
  null
  
)

export default component.exports